import { FunctionComponent, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import {
  SearchInput,
  Table,
  TableDataCell,
  TableHeadCell,
} from "@destination/components";

import ErrorComponent from "@components/ErrorComponent";
import LoadingComponent from "@components/LoadingComponent";
import { useSortQueryString } from "@hooks/useSortQueryString";

import { useOrganizationContext } from "@hooks/useOrganizationContext";
import { useSearchQueryString } from "@hooks/useSearchQueryString";
import { useUserContext } from "@hooks/useUserContext";
import { Money } from "@store/products/models/Money";
import { Order } from "@store/purchases/models/Order";
import { filter, sort, useGetOrders } from "@store/purchases/services/PurchaseService";

const Purchases: FunctionComponent = () => {
  const { t } = useTranslation(undefined, { keyPrefix: "store.purchases" });
  const { t: tPurchase } = useTranslation(undefined, { keyPrefix: "store.purchase" });

  const { activeSort, sortDirection, handleSort } = useSortQueryString();
  const { searchValue, handleSearch } = useSearchQueryString();

  const { user } = useUserContext();
  const { organization } = useOrganizationContext();

  const { orders, isLoading, error } = useGetOrders(organization.id);

  const [filteredOrders, setFilteredOrders] = useState<Order[] | undefined>();
  const [allOrders, setAllOrders] = useState<Order[] | undefined>();

  const purchaseDateFormatter = new Intl.DateTimeFormat(user.language, {
    dateStyle: "short",
  });

  useEffect(() => {
    const ordersFiltered = filter(orders, searchValue);
    setFilteredOrders(ordersFiltered);
  }, [orders, searchValue]);

  useEffect(() => {
    const sortedOrders = sort(filteredOrders, activeSort, sortDirection);
    setAllOrders(sortedOrders);
  }, [filteredOrders, activeSort, sortDirection]);



  const productNameColumnName = "product-name";
  const purchaseDateColumnName = "purchase-date";
  const purchaseAmountColumnName = "purchase-amount";
  const createdByColumnName = "created-by";
  const orderIdColumn = "order-id";

  function renderTableHeadCell(columnName: string) {
    return (
      <TableHeadCell
        sortActive={activeSort === columnName}
        sortDirection={sortDirection}
        onSort={() => handleSort(columnName)}
      >
        <div data-testid={`${columnName}-column`}>
          {tPurchase(`${columnName}.column-header`)}
        </div>
      </TableHeadCell>
    );
  }
  const renderAColumn = (id: string, value: string) => {
    return <TableDataCell data-testid={id}>
      {value}
    </TableDataCell>;
  };

  const renderAmountColumn = (id: string, amount: Money) => {
    return <TableDataCell data-testid={id}>
      {`${amount.currency} ${amount.value}`}
    </TableDataCell>;
  };

  const renderDateColumn = (id: string, date: Date) => {
    return (
      <TableDataCell data-testid={id}>
        {purchaseDateFormatter.format(new Date(date))}
      </TableDataCell>
    );
  };

  if (isLoading) return <LoadingComponent message={t("load.message")} />;
  if (error)
    return <ErrorComponent message={t("load.error.message")} />;

  return (
    <div data-testid="purchases-table-container">
      <div className="flex flex-row-reverse w-full">
        <div className="w-[;500px] mb-3">
          <SearchInput
            data-testid="search-purchases"
            value={searchValue}
            placeholder={t("search.placeholder")}
            onChange={handleSearch}
          />
        </div>
      </div>
      <div data-testid="purchases-table">
        <Table className="w-full" >
          <thead>
            <tr className="leading-[0px]" data-testid="thead">
              {renderTableHeadCell(productNameColumnName)}
              {renderTableHeadCell(purchaseDateColumnName)}
              {renderTableHeadCell(createdByColumnName)}
              {renderTableHeadCell(purchaseAmountColumnName)}
              {renderTableHeadCell(orderIdColumn)}
            </tr>
          </thead>

          <tbody data-testid="tbody">
            {allOrders?.map((order) => (
              <tr key={order.orderId}>
                {renderAColumn(`${order.orderId}-${productNameColumnName}-column-value`, order.productName)}
                {renderDateColumn(`${order.orderId}-${purchaseDateColumnName}-column-value`, order.orderDate)}
                {renderAColumn(`${order.orderId}-${createdByColumnName}-column-value`, order.orderCreatedBy.name)}
                {renderAmountColumn(`${order.orderId}-${purchaseAmountColumnName}-column-value`, order.productTotalPrice)}
                {renderAColumn(`${order.orderId}-${orderIdColumn}-column-value`, order.orderId)}
              </tr>
            ))}
          </tbody>
          <tfoot data-testid="tfoot">
            <tr>
              <td colSpan={6} className="pt-3">
                {t("count.message", { count: filteredOrders?.length })}
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>
    </div>
  );
};

export default Purchases;
