import { storeEndPoints } from "@api/Endpoints";
import { useUserContext } from "@hooks/useUserContext";

import { fetcher } from "@utils/swrUtils";
import { useMemo } from "react";
import useSWR from "swr";
import { Product } from "@store/products/models/Product";

export function useGetAllProducts() {
  const { getAccessToken } = useUserContext();

  const url = storeEndPoints.getAllProducts();

  const { data, error, isLoading } = useSWR<Product[], Error>(
    url,
    (url: string) => fetcher(url, getAccessToken)
  );

  return useMemo(
    () => ({ products: data, isLoading, error }),
    [data, isLoading, error]
  );
}

export function useGetSuggestedProducts() {
  const { getAccessToken } = useUserContext();

  const url = storeEndPoints.getSuggestedProducts();

  const { data, error, isLoading } = useSWR<Product[], Error>(
    url,
    (url: string) => fetcher(url, getAccessToken)
  );

  return useMemo(
    () => ({ products: data, isLoading, error }),
    [data, isLoading, error]
  );
}

export function filter(
  products: Product[] | undefined,
  value: string | undefined
): Product[] {
  if (!products) return [];

  if (!value) return products;

  const lowerCasedValue = value.toLocaleLowerCase();
  return products.filter(
    (item) =>
      item.name.toLocaleLowerCase().includes(lowerCasedValue) ||
      item.description.toLocaleLowerCase().includes(lowerCasedValue) ||
      item.about.toLocaleLowerCase().includes(lowerCasedValue)
  );
}
