const baseUrl = import.meta.env.VITE_APP_BACKEND_API_URL as string;

export const organizationEndpoints = {
  organizationName: () => `${baseUrl}/organizations/current/name`,
  organizationDetails: () => `${baseUrl}/organizations/current`,
  updateOrganizationName: () => `${baseUrl}/organizations/current/name`,
  organizationExternalIds: () => `${baseUrl}/organizations/current/externalIds`,
};

export const organizationUserEndpoints = {
  organizationUsers: () => `${baseUrl}/organizations/current/users`,
  addUser: () => `${baseUrl}/organizations/current/users`,
  updateUserRole: (userId: string) =>
    `${baseUrl}/organizations/current/users/${userId}`,
  removeUser: (userId: string) =>
    `${baseUrl}/organizations/current/users/${userId}`,
};

export const applicationEndpoints = {
  organizationApplications: () =>
    `${baseUrl}/organizations/current/applications`,
  applicationUsers: (applicationId: string) =>
    `${baseUrl}/organizations/current/applications/${applicationId}/users`,
  applicationRoles: (applicationId: string) =>
    `${baseUrl}/organizations/current/applications/${applicationId}/roles`,
  userApplications: (userId: string) =>
    `${baseUrl}/organizations/current/users/${userId}/applications`,
  updateOrDeleteApplicationUsers: (
    applicationId: string,
    userId: string
  ): string =>
    `${baseUrl}/organizations/current/applications/${applicationId}/users/${userId}`,
};

export const storeEndPoints = {
  getAllProducts: () => `${baseUrl}/commerce/products`,
  getSuggestedProducts: () => `${baseUrl}/commerce/products/suggested`,
  getOrders: (organizationId: string) =>
    `${baseUrl}/commerce/${organizationId}/orders`,
};

export const subscriptionEndPoints = {
  getSubscriptions: () => `${baseUrl}/commerce/subscriptions`,
  getSubscriptionById: (subscriptionId: string) =>
    `${baseUrl}/commerce/subscriptions/${subscriptionId}`,
  cancelSubscription: (subscriptionId: string) =>
    `${baseUrl}/commerce/subscriptions/${subscriptionId}`,
};

export const addressEndPoints = {
  getAddresses: () => `${baseUrl}/commerce/organizations/current/addresses`,
  createAddress: () => `${baseUrl}/commerce/organizations/current/addresses`,
  deleteAddress: (addressId: string) =>
    `${baseUrl}/commerce/organizations/current/addresses/${addressId}`,
  setDefaultBillingAddress: () =>
    `${baseUrl}/commerce/organizations/current/billing-addresses/default`,
};
